<!--
 * @Autor: Nehc
 * @Date: 2020-12-18 16:47:20
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-18 17:40:26
-->
<script>
import TablePage from '@/found/components/table_page';
// import Modal from '@/found/components/modal';
// import Form from '../form';

export default {
  extends: TablePage,
  components: {
    // Modal,
    // Form,
  },
  data() {
    return {
      formConfig: {},
    //   modalConfig: {
    //     title: '新增',
    //     visible: false,
    //     type: 'Modal',
    //   },
    };
  },
  async created() {
    await this.getConfigList('log_view');
  },
  methods: {
  },
};
</script>
