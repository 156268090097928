<!--
 * @Autor: Nehc
 * @Date: 2020-12-18 16:42:57
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-18 16:49:07
-->
<template>
  <div class="home">
    <LogView />
  </div>
</template>

<script>
import LogView from './table';

export default {
  name: 'LogView',
  components: {
    LogView,
  },
};
</script>
